import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformacaoBasicaAlunoComponent } from './informacao-basica-aluno.component';
import {
  MatFormFieldModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatButtonModule,
} from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [InformacaoBasicaAlunoComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    NgxMaskModule,
    MatButtonModule,
    FlexLayoutModule.withConfig({ addFlexToParent: false }),
  ],
  exports: [InformacaoBasicaAlunoComponent],
})
export class InformacaoBasicaAlunoModule {}
