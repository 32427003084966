import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApostilaDigital } from 'src/app/models/apostila-digital';
import { stringify } from 'querystring';
import { Opcao } from 'src/app/models/opcao';

@Injectable({
  providedIn: 'root',
})
export class ApostilaDigitalService {
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {}

  getApostilas(pageNumber: number, pageSize: number, site: number) {
    return this.http.get<ApostilaDigital[]>(
      `${this.baseUrl}/apostilasdigitais?${stringify({ pageNumber, pageSize, site })}`,
      {
        observe: 'response',
      }
    );
  }

  getOpcoesApostilas(site: number) {
    return this.http.get<Opcao[]>(`${this.baseUrl}/apostilasdigitais/opcoes-apostilasdigitais?site=${site}`);
  }

  getApostila(id: number) {
    return this.http.get<ApostilaDigital>(`${this.baseUrl}/apostilasdigitais/${id}`);
  }

  salvar(apostilaDigital: ApostilaDigital) {
    return this.http.post(`${this.baseUrl}/apostilasdigitais`, apostilaDigital);
  }

  atualizar(apostilaDigital: ApostilaDigital) {
    return this.http.put(`${this.baseUrl}/apostilasdigitais/${apostilaDigital.id}`, apostilaDigital);
  }

  remover(id: number) {
    return this.http.delete(`${this.baseUrl}/apostilasdigitais/${id}`);
  }

  getSumario(id: number) {
    return this.http.get<any[]>(`${this.baseUrl}/apostilasdigitais/${id}/sumario`);
  }
}
