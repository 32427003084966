import { Injectable, Inject } from '@angular/core';
import { Instituicao } from 'src/app/models/instituicao';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Opcao } from 'src/app/models/opcao';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root',
})
export class InstituicaoService {
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {}

  atualizar(instituicao: Instituicao) {
    return this.http.put(`${this.baseUrl}/instituicoes/${instituicao.id}`, instituicao);
  }

  salvar(instituicao: Instituicao) {
    return this.http.post(`${this.baseUrl}/instituicoes`, instituicao);
  }

  getInstituicao(id: number) {
    return this.http.get<Instituicao>(`${this.baseUrl}/instituicoes/${id}`);
  }

  remover(id: number) {
    return this.http.delete(`${this.baseUrl}/instituicoes/${id}`);
  }

  getInstituicoes(pageNumber: number, pageSize: number, uf: string) {
    return this.http.get<Instituicao[]>(`${this.baseUrl}/instituicoes?${stringify({ pageNumber, pageSize, uf })}`, {
      observe: 'response',
    });
  }

  getOpcoesInstituicao(uf: string) {
    const params = new HttpParams().set('uf', uf);
    return this.http.get<Opcao[]>(`${this.baseUrl}/instituicoes/opcoes-instituicao`, { params });
  }
}
