import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Modulo } from 'src/app/models/modulo';
import { Opcao } from 'src/app/models/opcao';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root',
})
export class ModuloService {
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {}

  atualizar(modulo: Modulo) {
    return this.http.put(`${this.baseUrl}/modulos/${modulo.id}`, modulo);
  }

  salvar(modulo: Modulo) {
    return this.http.post(`${this.baseUrl}/modulos`, modulo);
  }

  getModulo(id: number) {
    return this.http.get<Modulo>(`${this.baseUrl}/modulos/${id}`);
  }

  remover(id: number) {
    return this.http.delete(`${this.baseUrl}/modulos/${id}`);
  }

  getModulos(pageNumber: number, pageSize: number, site: any) {
    return this.http.get<Modulo[]>(`${this.baseUrl}/modulos?${stringify({ pageNumber, pageSize, site })}`, {
      observe: 'response',
    });
  }

  getOpcoesModulo(id: number) {
    return this.http.get<Opcao[]>(`${this.baseUrl}/modulos/opcoes-modulo?site=${id}`);
  }

  getModulosPorSites(ids: any[]) {
    return this.http.get<any[]>(`${this.baseUrl}/modulos/sites?${ids.join('&')}`);
  }
}
