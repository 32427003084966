import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Usuario } from 'src/app/models/usuario';
import { AlteracaoSenha } from 'src/app/models/alteracao-senha';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {}

  getUsuarios(pageIndex: number, pageSize: number) {
    return this.http.get<Usuario[]>(`${this.baseUrl}/usuarios?pageNumber=${pageIndex + 1}&pageSize=${pageSize}`, {
      observe: 'response',
    });
  }

  getUsuario(id: number): Observable<Usuario> {
    return this.http.get<Usuario>(`${this.baseUrl}/usuarios/${id}`);
  }

  salvar(usuario: Usuario): Observable<any> {
    return this.http.post(`${this.baseUrl}/usuarios`, usuario);
  }

  atualizar(usuario: Usuario): Observable<any> {
    return this.http.put(`${this.baseUrl}/usuarios/${usuario.id}`, usuario);
  }

  remover(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/usuarios/${id}`);
  }

  alterarSenha(alteracaoSenha: AlteracaoSenha): Observable<any> {
    return this.http.patch(`${this.baseUrl}/usuarios/senha`, alteracaoSenha);
  }

  reenviarConfirmacaoEmail(id: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/usuarios/${id}/confirmacao-email`, {});
  }
}
