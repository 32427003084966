import { Injectable } from '@angular/core';
import { Uf } from 'src/app/models/uf';

@Injectable({
  providedIn: 'root'
})
export class EstadoService {
  constructor() {}

  getEstados(): any[] {
    return Object.keys(Uf).map(key => {
      return {
        uf: key,
        nome: Uf[key]
      };
    });
  }
}
