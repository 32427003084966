import { QuestaoProva } from './questao-prova';
import { TipoComentario } from './tipo-comentario';

export class ItemApostila {
  id: number;
  index: number;
  tipoItem: string;
  titulo?: string;
  conteudo?: string;
  questaoProva: QuestaoProva;

  constructor() {
    this.conteudo = '';
    this.questaoProva = new QuestaoProva();
  }
}
