import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-informacao-basica-aluno',
  templateUrl: './informacao-basica-aluno.component.html',
  styleUrls: ['./informacao-basica-aluno.component.scss'],
})
export class InformacaoBasicaAlunoComponent implements OnInit {
  formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<InformacaoBasicaAlunoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      email: ['', []],
      telefone: ['', []],
      dataDeCadastro: ['', []],
    });
    this.formGroup.disable();
  }
}
