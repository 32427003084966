import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SubModulo } from 'src/app/models/submodulo';
import { Opcao } from 'src/app/models/opcao';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root',
})
export class SubmoduloService {
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {}

  atualizar(submodulo: SubModulo) {
    return this.http.put(`${this.baseUrl}/submodulos/${submodulo.id}`, submodulo);
  }

  salvar(submodulo: SubModulo) {
    return this.http.post(`${this.baseUrl}/submodulos`, submodulo);
  }

  getSubModulo(id: number) {
    return this.http.get<SubModulo>(`${this.baseUrl}/submodulos/${id}`);
  }

  remover(id: number) {
    return this.http.delete(`${this.baseUrl}/submodulos/${id}`);
  }

  getSubModulos(pageNumber: number, pageSize: number, modulo: any) {
    return this.http.get<SubModulo[]>(`${this.baseUrl}/submodulos?${stringify({ pageNumber, pageSize, modulo })}`, {
      observe: 'response',
    });
  }

  getOpcoesSubModulos(modulo: any) {
    const params = new HttpParams().set('modulo', modulo);
    return this.http.get<Opcao[]>(`${this.baseUrl}/submodulos/opcoes-submodulo`, { params });
  }
}
